import { 
    getDocs, query,collection, 
    orderBy, limit, startAfter, 
    Timestamp } from "firebase/firestore"
import {db, loadUserBlogsCallableFunction, loadUserBlogsLastWeekCallableFunction} from "./config"
import {getStartOfWeek} from "./utility"

// collection ref
const blogsRef = collection(db, "blogs");
const usersRef = collection(db, "users");
const limitSize = 10;

const loadUsers = async (lastVisible: any = null) => {
    let q;
    
    try{

        if(lastVisible)
            q = query(usersRef, startAfter(lastVisible), limit(limitSize))
        else //load first instance
            q = query(usersRef, limit(limitSize))
        
        
        const documentSnapshots = await getDocs(q)

        //update index
        lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];

        return { documentSnapshots, lastVisible }

    }catch(err)
    {
        console.log("loadUsers exception");
        throw err;
    }
}   

const loadBlogs = async (lastVisible: any = null) => {
    let q;

    try{

        if(lastVisible)
            q = query(blogsRef, limit(limitSize), orderBy("created_at", "desc"), startAfter(lastVisible))
        else
            q = query(blogsRef, limit(limitSize), orderBy("created_at", "desc"))

        const documentSnapshots = await getDocs(q);

        lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];

        return { documentSnapshots, lastVisible }

    }catch(err)
    {
        console.log("loadUsers exception");
        throw err;
    }    
}

const loadUserBlogs = async (userId: string,lastVisible: any = null,isLastWeekActive = false) => {
    let q;

    if(!userId)
        throw "Invalid user requested";

    try{

        const date = Timestamp.fromDate(getStartOfWeek());
        let results;

        //run callable functions
        if(!isLastWeekActive)
        {
            results = await loadUserBlogsCallableFunction({userId:userId,lastVisible:lastVisible})            
        }
        else
        {
            results = await loadUserBlogsLastWeekCallableFunction({userId:userId,lastVisible:lastVisible})
        }

        const data: any = results.data;
        const docs = data.docs ? data.docs:null;
        const isEmpty = data.isEmpty;

        //Note: startofWeek and endofWeek are empty for current week's function call
        const startOfWeek = data.startOfWeek;
        const endOfWeek = data.endOfWeek;
        lastVisible = data.lastVisible ? data.lastVisible:null;

        return {docs, lastVisible, isEmpty,startOfWeek,endOfWeek} ;

    }catch(err)
    {
        console.log("loadUserBlogs exception");
        throw err;
    }    
}

export {limitSize, usersRef,blogsRef,loadUsers, loadBlogs, loadUserBlogs}