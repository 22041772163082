
import { defineComponent } from 'vue';
import Blogs from '@/components/Blogs.vue';
import swal from "sweetalert2";
import { auth, userRefs, db } from "../firebase/config"
import {
  signOut,
  onAuthStateChanged,
  updateProfile,
User
} from "firebase/auth"
import { updateDoc,where,query, getDocs, doc } from "@firebase/firestore";

export default defineComponent({
  name: 'Home',
  components: {
    Blogs,
  },
  data(){
    return {
      errorTitle: "Error",
      loggedUser:null as any,
    }
  },
  mounted(){
    this.checkCurrentUserState()
  },
  methods:{
    showError(error: string){
      swal.fire({
        title: this.errorTitle,
        html: error,
        icon: 'error',
        backdrop: false,
      })
    },
    checkCurrentUserState(){
      const unsub = onAuthStateChanged(auth, (user) => {
        if(user && user.emailVerified)
          this.loggedUser = user 
        else
        {
          this.loggedUser = null
        }
          
      })

      unsub();
    },
    async signout(){
      //log user out
      try{
        await signOut(auth);    
        this.loggedUser = null    
      } 
      catch(err)
      {
        //failed to logout
        console.log(err);
      }    

    },
  },
});
