

import { defineComponent } from 'vue';
import BlogType from "../Types/BlogType"
import UserType from "../Types/UserType"
import { loadUsers,limitSize, loadUserBlogs } from "../firebase/crud"
import { assertStringLiteral } from '@babel/types';

export default defineComponent({
    data(){
        return {
            users:[] as any[],
            userBlogs:{} as any,
            isLoading:false,
            blogsFound:false,
            allUsersLoaded: false,
            allBlogsLoaded: false,
            lastVisibleUser: null,
            lastVisibleBlog: null,
            lastVisibleUserBlog: null,
            activeUser: 0,
            activeUserId:"",
            isLastWeekActive: false,
            endOfWeek: new Date(),
            startOfWeek: new Date(),
        }
    },
    emits: ["error"],
    computed:{
        usersWithBlogs(): any [] {
            return this.users.filter((user: UserType) => {
                this.userBlogs[user.user_id].length
            })
        }
    },
    async beforeMount(){
        // load blog posts and handle data
        this.isLoading = true;
        await this.loadContent();        
    },
    mounted(){
        // handle infinite scroll
        window.onscroll = async () => {

            //prevent loading data while fetching is currently running
            if(this.isLoading)
                return;
            
            const totalPageHeight = document.body.scrollHeight; 

            const scrollPoint = window.scrollY + window.innerHeight;

            // check if we hit the bottom of the page
            if(scrollPoint >= totalPageHeight)
            {
                if(!this.allBlogsLoaded)
                {
                    await this.loadmore();
                }
            }
        }
    },
    methods:{
        async loadlastWeekBlogs(){            
            //reset data
            this.resetData();

            this.isLastWeekActive = true;

            this.isLoading = true;
            await this.loadContent(); 
        },
        resetData(){
            //this.resetUsersData();
            this.users = [];
            this.userBlogs = {};
            this.blogsFound = false;
            this.allUsersLoaded =  false;
            this.allBlogsLoaded= false;
            this.lastVisibleUser= null;
            this.lastVisibleBlog= null;
            this.lastVisibleUserBlog= null;
            this.activeUser= 0;
            this.activeUserId="";
            this.isLastWeekActive = false;
        },
        resetUsersData(){
            for(let key in this.userBlogs)
            {
                this.userBlogs[key] = [];
            }
        },
        async loadmore(){
            this.isLoading = true;
            await this.loadUserBlogs(this.activeUserId);
            this.isLoading = false;
        },  
        async loadContent(){
            await this.loadUsers();

            if(this.users.length == 0)
            {
                this.isLoading = false;
                this.allUsersLoaded =  true;
                this.allBlogsLoaded= true;
                return;
            }

            this.activeUserId = this.users[this.activeUser].user_id;

            //load blogs for current active user
            await this.loadUserBlogs(this.activeUserId);
            this.isLoading = false;
        },
        async loadUsers(){

            try{

                const {documentSnapshots, lastVisible} = await loadUsers(this.lastVisibleUser);

                this.lastVisibleUser = lastVisible;

                if(documentSnapshots.empty)
                {
                    this.allUsersLoaded = true;
                    return;
                }

                if(documentSnapshots.size < limitSize)
                {
                    this.allUsersLoaded = true;
                }

                //add loaded users to dom
                documentSnapshots.docs.forEach((doc) => {
                    const user: UserType = Object.assign({id: doc.id} as UserType, doc.data())

                    this.users.push(user);
                    this.userBlogs[user.user_id] = [] as BlogType[];
                })                

            }catch (err)
            {
                console.log(err);
                this.$emit("error", err)
            }
        },
        async loadUserBlogs(currentActiveUserId: string){
            try{

                console.log("loading blogs for user = ",currentActiveUserId);
                const {docs, lastVisible, isEmpty,startOfWeek, endOfWeek} = await loadUserBlogs(currentActiveUserId, this.lastVisibleUserBlog,this.isLastWeekActive);

                console.log("returned server dates", startOfWeek,endOfWeek); 
                this.lastVisibleUserBlog = lastVisible;

                this.startOfWeek = startOfWeek
                this.endOfWeek = endOfWeek        

                if(isEmpty)
                {  
                    //load next user's blogs
                    await this.loadNextUser();                      
                    return;
                }

                this.blogsFound = true;

                console.log("blog found for user = ",currentActiveUserId)

                //add loaded blogs to user
                if(docs)
                {
                    docs.forEach((doc:any) => {
                        const blog: BlogType = doc;
                        // Object.assign({id:doc.id} as BlogType, doc.data())

                        this.userBlogs[currentActiveUserId].push(blog);

                        console.log(blog);

                    })

                    //load more blogs if user's blogs are loaded
                    if(docs.length< limitSize)
                    {   
                        //load next user's blogs
                        await this.loadNextUser();                    
                    }
                }
                

            }catch (err)
            {
                this.$emit("error", err)
            }
        },
        async loadNextUser(){
            this.isLoading = true;
            this.activeUser++;            

            //if all current users displayed, check for more users
            if(this.activeUser >= this.users.length)
            {
                if(this.allUsersLoaded)
                {
                    this.allBlogsLoaded = true;
                    return
                }

                await this.loadUsers();

                //return if no additional users loaded
                if(this.activeUser >= this.users.length)
                    return;                

            }

            //load next user's blogs
            this.activeUserId = this.users[this.activeUser].user_id;
            //reset blog index
            this.lastVisibleUserBlog = null;

            await this.loadUserBlogs(this.activeUserId);
            this.isLoading = false;
        },
    }
})
