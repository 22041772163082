const getStartOfWeek = () => {
    return getMonday();
}

const getMonday = () => {
    const d = new Date();
    const day = d.getDay();

    const diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday

    const firstMonday = new Date(d.setDate(diff))
    firstMonday.setUTCHours(0-8-Math.floor(firstMonday.getTimezoneOffset()/60),0,0,0)
    //console.log("returned date = ", firstMonday)
    return firstMonday;
}

export { getStartOfWeek }