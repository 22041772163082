import { initializeApp } from "firebase/app";
import { getFirestore, collection } from "firebase/firestore";
import { getFunctions,httpsCallable  } from 'firebase/functions';
import { getAuth } from "firebase/auth";


const firebaseConfig = {
    apiKey: "AIzaSyA0rcYyelnRWJ7citNYwWnfKQiDNQCc37M",
    authDomain: "bocce-f2fbf.firebaseapp.com",
    projectId: "bocce-f2fbf",
    storageBucket: "bocce-f2fbf.appspot.com",
    messagingSenderId: "354072401062",
    appId: "1:354072401062:web:596d2e1244c0923979d067",
    measurementId: "G-LEPT2VY7D5"
  };
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth();
const db = getFirestore();
const functions = getFunctions(app);
const loadUserBlogsCallableFunction = httpsCallable(functions, 'loadUserBlogs');
const loadUserBlogsLastWeekCallableFunction = httpsCallable(functions,'loadUserBlogsLastWeek');
const storeUserBlogCallableFunction = httpsCallable(functions,'storeUserBlog');
const crawlSiteCallableFunction = httpsCallable(functions,'crawlSite');
const userRefs = collection(db, "users");

export { auth,userRefs,db,functions,loadUserBlogsCallableFunction,loadUserBlogsLastWeekCallableFunction,storeUserBlogCallableFunction,crawlSiteCallableFunction};
