import { FirebaseError } from "firebase/app";

class FirebaseUtil{
    readonly errors:any[];

    constructor()
    {
        this.errors = [];
    }

    handlerError (error: FirebaseError): string {
        const code = error.code;
        switch(code)
        {
            case 'auth/email-already-in-use':
                return "Account already exists";
            case 'auth/wrong-password':
                return "Invalid email or password";
            case 'auth/user-not-found':
                return "User not found";
            case 'auth/admin-restricted-operation':
                return "Failed to create an account";
            case 'auth/weak-password':
                return error.message.replace('Firebase: ','');
            default:
                return error.message.replace('Firebase: ','');
            

        }
    }


}

export default FirebaseUtil;